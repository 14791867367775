import axios from 'axios';
import jwt_decode from 'jwt-decode';
import { loginSuccess } from "containers/Login/actions";
import { logout } from "containers/Logout/actions";
import { tenantId } from 'config/envConfig';
import { refreshToken } from 'containers/Login/resources';
import _ from 'lodash';
import { store } from 'App';
import { setToken } from 'api-provider';

export const decodeJWT = (name, data) => {
  const encodedJWT = data || JSON.parse(localStorage.getItem(name));

  if (encodedJWT) {
    const { sectionsEnabled } = jwt_decode(encodedJWT);
    return sectionsEnabled;
  }
};

export const handlePermissions = (name, data, setPermissions, dispatch) => {
  const decodedPermissions = decodeJWT(name, data);
  if (!decodedPermissions) {
    dispatch(logout());
  }
  setPermissions(decodedPermissions ?? []);
  localStorage.setItem(name, JSON.stringify(data));
};

export const handleSessionExpiration = (dispatch, date, data) => {
  localStorage.setItem("sessionTimestamp", date);

  const filteredData = Object.fromEntries(
    Object.entries(data).filter(
      ([key, value]) =>
        value &&
        !key.includes("userPermissions") &&
        !key.includes("featureFlags")
    )
  );

  dispatch(loginSuccess(filteredData));
};

const getRefreshToken = _.throttle(async (authServer) => {
  return await axios.request(refreshToken(authServer))
    .then((response) => response.data)
    .catch((error) => window.location.href = '/logout');
}, 2000, { leading: true, trailing: false });

const updateTokenData = (newToken) => {
  localStorage.setItem(`geop_oauth_${tenantId}`, JSON.stringify(newToken));
  store.dispatch(setToken(`${newToken?.token_type} ${newToken?.access_token}`));
};

export const checkAuthServerToken = async () => {
  const authServer = JSON.parse(localStorage.getItem(`geop_oauth_${tenantId}`));
  const { exp } = jwt_decode(authServer.access_token);
  const expirationDate = new Date(exp * 1000);

  if (expirationDate < Date.now()) {
    const newToken = await getRefreshToken(authServer);
    updateTokenData(newToken);
    return newToken;
  }

  return null;
};
